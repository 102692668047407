exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-additional-question-tsx": () => import("./../../../src/pages/additional-question.tsx" /* webpackChunkName: "component---src-pages-additional-question-tsx" */),
  "component---src-pages-before-we-begin-tsx": () => import("./../../../src/pages/before-we-begin.tsx" /* webpackChunkName: "component---src-pages-before-we-begin-tsx" */),
  "component---src-pages-check-address-tsx": () => import("./../../../src/pages/Check-address.tsx" /* webpackChunkName: "component---src-pages-check-address-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-entry-exit-date-tsx": () => import("./../../../src/pages/Entry-ExitDate.tsx" /* webpackChunkName: "component---src-pages-entry-exit-date-tsx" */),
  "component---src-pages-first-tsx": () => import("./../../../src/pages/first.tsx" /* webpackChunkName: "component---src-pages-first-tsx" */),
  "component---src-pages-first-upload-tsx": () => import("./../../../src/pages/first-upload.tsx" /* webpackChunkName: "component---src-pages-first-upload-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-signin-tsx": () => import("./../../../src/pages/signin.tsx" /* webpackChunkName: "component---src-pages-signin-tsx" */),
  "component---src-pages-signup-tsx": () => import("./../../../src/pages/signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */),
  "component---src-pages-sponsor-contact-tsx": () => import("./../../../src/pages/Sponsor-Contact.tsx" /* webpackChunkName: "component---src-pages-sponsor-contact-tsx" */),
  "component---src-pages-tax-application-tsx": () => import("./../../../src/pages/tax-application.tsx" /* webpackChunkName: "component---src-pages-tax-application-tsx" */),
  "component---src-pages-tax-filing-tsx": () => import("./../../../src/pages/TaxFiling.tsx" /* webpackChunkName: "component---src-pages-tax-filing-tsx" */),
  "component---src-pages-year-select-tsx": () => import("./../../../src/pages/Year-select.tsx" /* webpackChunkName: "component---src-pages-year-select-tsx" */)
}

